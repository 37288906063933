import { query, where, collection, getDocs } from "firebase/firestore";
import React, { useState } from "react";
import { useFirebase } from "../../../context/FirebaseContext";
import ReportFilters from "../../../components/Report/ReportFilters";
import { useUserContext } from "../../../context/UserContext";
import DateUtil from "../../../util/DateUtil";
import { useAdminApiClient } from "../../../hooks/useApiClient";
import { KycHtmlTemplate } from "./KycHtmlTemplate";
import { openPdf } from "../../../util/pdf";
import { exportKycToExcel } from "./KycExportToExcel";
import ModalError from "../../../components/Modals/ModalError";
import { Box, HStack, VStack, Text } from "@chakra-ui/react";
import { PageHeading } from "../../../components/PageHeading/PageHeading";
import RoundedBackButton from "../../../components/ReoundedBackButton/RoundedBackButton";
import { useNavigate } from "react-router-dom";

const KycReport = () => {
  const navigate = useNavigate();
  const { db } = useFirebase();
  const { user } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const apiClient = useAdminApiClient();
  const [alert, setAlert] = useState({
    isOpen: false,
    subTitle: "",
  });

  const loadDataReport = async (params: any) => {
    const {
      startDate,
      endDate,
      selectedBusiness,
      selectedVenues = [],
    } = params;
    const venues = selectedVenues.map((venue: any) => venue.value);
    const venuesList = selectedVenues
      .map((venue: any) => venue.label)
      .join(", ");
    try {
      const docsRef = collection(db, "references");
      let queryRef;
      let snapshot;
      queryRef = query(
        docsRef,
        where("timestamp", ">=", new Date(startDate)),
        where("timestamp", "<=", new Date(`${endDate} 23:59:59`)),
        where("venueId", "in", venues)
      );
      snapshot = await getDocs(queryRef);

      const records: any = [];
      if (!!snapshot && !snapshot.empty) {
        snapshot.forEach((record) => {
          if (
            !!record.data()?.status &&
            record.data()?.status?.toLowerCase() != "pending"
          ) {
            const {
              memberName = "...",
              venueName = "...",
              venueId,
              badgeNumber,
              status,
              timestamp,
            } = record.data();
            records.push({
              memberName,
              venueName,
              venueId,
              badgeNumber,
              date: DateUtil.getFormattedDate(
                timestamp?.toDate(),
                "dd/MM/yyyy"
              ),
              time: DateUtil.getFormattedDate(timestamp?.toDate(), "HH:mm:ss"),
              status,
              timestamp,
            });
          }
        });
      }
      if (records.length === 0) {
        setAlert({
          isOpen: true,
          subTitle: "No record available for selected period.",
        });
        return null;
      }

      const sortedRecord = records.sort(
        (a: any, b: any) => a.timestamp - b.timestamp
      );
      const payload = {
        startDate: DateUtil.getFormattedDate(new Date(startDate), "dd/MM/yyyy"),
        endDate: DateUtil.getFormattedDate(new Date(endDate), "dd/MM/yyyy"),
        business: selectedBusiness.label,
        venues: venuesList,
        totalSuccess: sortedRecord.filter((record: any) =>
          ["success", "successful"].includes(
            record.status?.toLowerCase()?.trim()
          )
        ).length,
        totalConsider: sortedRecord.filter((record: any) =>
          ["consider"].includes(record.status?.toLowerCase()?.trim())
        ).length,
        totalReject: sortedRecord.filter((record: any) =>
          ["reject", "rejected", "fail"].includes(
            record.status?.toLowerCase()?.trim()
          )
        ).length,
        printed: DateUtil.getFormattedDate(new Date(), "dd/MM/yyyy HH:mm:ss"),
        user: user?.displayName || user?.email,
        items: sortedRecord,
      };

      return payload;
    } catch (_err) {
      console.log("Failed generate KYC report", _err);
      setAlert({
        isOpen: true,
        subTitle: "Something went wrong, while generating the report.",
      });
      return null;
    }
  };

  const onGeneratePdf = async (params: any) => {
    setIsLoading(true);
    const payload = await loadDataReport(params);
    if (payload !== null) {
      const pdf = await apiClient.generatePdf(
        "kyc-report",
        KycHtmlTemplate,
        payload,
        "landscape"
      );
      openPdf(pdf);
    }
    setIsLoading(false);
  };

  const onGenerateExcel = async (params: any) => {
    setIsLoading(true);
    const payload = await loadDataReport(params);
    if (payload !== null) {
      await exportKycToExcel(payload);
    }
    setIsLoading(false);
  };

  return (
    <>
      <PageHeading>
        <HStack spacing={4}>
          <RoundedBackButton onClick={() => navigate(-1)} />
          <VStack alignItems="start">
            <Box>
              <PageHeading.Title>KYC REPORT</PageHeading.Title>
            </Box>
            <Text color="white" fontFamily="Poppins" fontSize="13px">
              Select a period and generate the report.
            </Text>
          </VStack>
        </HStack>
      </PageHeading>
      <Box paddingX="10" height="60%">
        <ReportFilters
          onGeneratePdf={onGeneratePdf}
          onGenerateExcel={onGenerateExcel}
          isLoading={isLoading}
        />
        <ModalError
          isOpen={alert.isOpen}
          onClose={() =>
            setAlert({
              isOpen: false,
              subTitle: "",
            })
          }
          title="KYC report"
          subTitle={alert.subTitle}
        />
      </Box>
    </>
  );
};

export default KycReport;
