import { Box, HStack, VStack, Text } from "@chakra-ui/react";
import React, { useState } from "react";
import ModalError from "../../../components/Modals/ModalError";
import BusinessReportFilters from "../../../components/Report/BusinessReportFilters";
import { useUserContext } from "../../../context/UserContext";
import { useAdminApiClient } from "../../../hooks/useApiClient";
import DateUtil from "../../../util/DateUtil";
import { openPdf } from "../../../util/pdf";
import { exportMemberToExcel } from "./MemberExportToExcel";
import { MemberHtmlTemplate } from "./MemberHtmlTemplate";
import { useNavigate } from "react-router-dom";
import { PageHeading } from "../../../components/PageHeading/PageHeading";
import RoundedBackButton from "../../../components/ReoundedBackButton/RoundedBackButton";

const BusinessMemberReport = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  const [isLoading, setIsLoading] = useState(false);
  const apiClient = useAdminApiClient();
  const [alert, setAlert] = useState({
    isOpen: false,
    subTitle: "",
  });

  const loadDataReport = async (params: any) => {
    const {
      startDate,
      endDate,
      selectedBusiness,
      selectedVenues = [],
    } = params;
    const venues = selectedVenues.map((venue: any) => venue._id);
    const venuesList = selectedVenues
      .map((venue: any) => venue.label)
      .join(", ");

    try {
      const res = await apiClient.getMemberReport({
        startDate,
        endDate,
        venues,
      });
      const records: any = [];
      let totalCardItEnable = 0;
      let totalTapItEnable = 0;
      for (let item of res?.data?.items) {
        if (item?.externalMemberData?.isMandateCreated) {
          totalCardItEnable++;
        }

        totalTapItEnable++;

        records.push({
          firstName: item.firstName,
          lastName: item.lastName,
          badgeNumber: item.badgeNo,
          connectedDate: DateUtil.getFormattedDate(
            new Date(item.createdAt),
            "dd/MM/yyyy"
          ),
          isTapIt: "✓",
          isCardIt: item?.externalMemberData?.isMandateCreated ? "✓" : "-",
        });
      }
      if (records.length === 0) {
        setAlert({
          isOpen: true,
          subTitle: "No record available for selected period.",
        });
        return null;
      }
      const payload = {
        startDate: DateUtil.getFormattedDate(new Date(startDate), "dd/MM/yyyy"),
        endDate: DateUtil.getFormattedDate(new Date(endDate), "dd/MM/yyyy"),
        business: selectedBusiness,
        venues: venuesList,
        printed: DateUtil.getFormattedDate(new Date(), "dd/MM/yyyy HH:mm:ss"),
        user: user?.displayName || user?.email,
        items: records,
        totalRecord: records.length,
        totalCardItEnable,
        totalTapItEnable,
      };

      return payload;
    } catch (_err) {
      console.log("Failed generate member report", _err);
      setAlert({
        isOpen: true,
        subTitle: "Something went wrong, while generating the report.",
      });
      return null;
    }
  };

  const onGeneratePdf = async (params: any) => {
    setIsLoading(true);
    const payload = await loadDataReport(params);
    if (payload !== null) {
      const pdf = await apiClient.generatePdf(
        "member-report",
        MemberHtmlTemplate,
        payload,
        "landscape"
      );
      openPdf(pdf);
    }
    setIsLoading(false);
  };

  const onGenerateExcel = async (params: any) => {
    setIsLoading(true);
    const payload = await loadDataReport(params);
    if (payload !== null) {
      await exportMemberToExcel(payload);
    }
    setIsLoading(false);
  };

  return (
    <>
      <PageHeading>
        <HStack spacing={4}>
          <RoundedBackButton onClick={() => navigate(-1)} />
          <VStack alignItems="start">
            <Box>
              <PageHeading.Title>MEMBER REPORT</PageHeading.Title>
            </Box>
            <Text color="white" fontFamily="Poppins" fontSize="13px">
              Select a period and generate the report.
            </Text>
          </VStack>
        </HStack>
      </PageHeading>
      <Box paddingX="10" height="60%">
        <BusinessReportFilters
          onGeneratePdf={onGeneratePdf}
          onGenerateExcel={onGenerateExcel}
          isLoading={isLoading}
        />
        <ModalError
          isOpen={alert.isOpen}
          onClose={() =>
            setAlert({
              isOpen: false,
              subTitle: "",
            })
          }
          title="Member report"
          subTitle={alert.subTitle}
        />
      </Box>
    </>
  );
};

export default BusinessMemberReport;
