import { Link as ChLink, Text, VStack } from "@chakra-ui/layout";
import React from "react";
import { Link } from "react-router-dom";
import { Card } from "../../components/Card/Card";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import { useBusiness } from "../../context/BusinessContext";

export const BusinessReports = () => {
  const business = useBusiness();

  return (
    <>
      <PageHeading>
        <PageHeading.Title>Reports</PageHeading.Title>
      </PageHeading>
      <PageContent>
        <Card w="100%">
          <VStack w="100%" alignItems="start">
            <Card>
              <ChLink
                as={Link}
                to={`/admin/businesses/${business?._id}/reports/kyc`}
                fontSize="xl"
                fontWeight="bold"
              >
                KYC Reports
              </ChLink>
              <Text fontSize="md">
                Download KYC report respective to the venue
              </Text>
            </Card>
            <Card>
              <ChLink
                as={Link}
                to={`/admin/businesses/${business?._id}/reports/member`}
                fontSize="xl"
                fontWeight="bold"
              >
                Member Reports
              </ChLink>
              <Text fontSize="md">
                Download member report respective to the venue
              </Text>
            </Card>
            <Card>
              <ChLink
                as={Link}
                to={`/admin/businesses/${business?._id}/reports/otp`}
                fontSize="xl"
                fontWeight="bold"
              >
                OTP Reports
              </ChLink>
              <Text fontSize="md">
                Download OTP report respective to the venue
              </Text>
            </Card>
          </VStack>
        </Card>
      </PageContent>
    </>
  );
};
