import { Box, VStack } from "@chakra-ui/layout";
import { HStack, Image } from "@chakra-ui/react";
import ebetLogo from "assets/eBet_Logo.svg";
import React, { useCallback, useEffect } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AppTitleV2 } from "./components/AppTitle/AppTitleV2";
import { CurrentUserV2 } from "./components/CurrentUser/CurrentUserV2";
import { ErrorHandler } from "./components/ErrorBoundary/ErrorBoundary";
import { ErrorMessage } from "./components/ErrorMessage/ErrorMessage";
import { LayoutV2 } from "./components/Layout/LayoutV2";
import { LoadingIndicator } from "./components/LoadingIndicator/LoadingIndicator";
import { AdminMenuV2 } from "./components/Menu/AdminMenuV2";
import { HomeMenuV2 } from "./components/Menu/HomeMenuV2";
import { BusinessContextProvider } from "./context/BusinessContext";
import { useLayoutContext } from "./context/LayoutContext";
import { ModelContextProvider } from "./context/ModelContext";
import { useUserContext } from "./context/UserContext";
import { VenueContextProvider } from "./context/VenueContext";
import { ApikeyDetail } from "./pages/Apikeys/ApikeyDetail";
import { Apikeys } from "./pages/Apikeys/Apikeys";
import { BusinessDetailV2 } from "./pages/Business/BusinessDetailV2";
import { Businesses } from "./pages/Business/Businesses";
import { ChangePassword } from "./pages/ChangePassword/ChangePassword";
import { Login } from "./pages/Login/Login";
import { MemberDetail } from "./pages/Members/MemberDetail";
import { Members } from "./pages/Members/Members";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { BusinessReports } from "./pages/Reports/BusinessReports";
import BusinessKycReport from "./pages/Reports/kyc-report/BusinessKycReport";
import KycReport from "./pages/Reports/kyc-report/KycReport";
import BusinessMemberReport from "./pages/Reports/member-report/BusinessMemberReport";
import MemberReport from "./pages/Reports/member-report/MemberReport";
import BusinessOtpReport from "./pages/Reports/otp-report/BusinessOtpReport";
import OtpReport from "./pages/Reports/otp-report/OtpReport";
import { Reports } from "./pages/Reports/Reports";
import Sessions from "./pages/Sessions/Sessions";
import { Settings } from "./pages/Settings/Settings";
import { UserDetail } from "./pages/Users/UserDetail";
import { Users } from "./pages/Users/Users";
import { UserUpdate } from "./pages/Users/UserUpdate";
import { AddVenue } from "./pages/Venues/AddVenue";
import { VenueDetail } from "./pages/Venues/VenueDetail";
import { Venues } from "./pages/Venues/Venues";

const getCurrentMenu = (businessId: string | undefined, pathName: string) => {
  const isAdmin = pathName.includes("admin");
  return isAdmin ? AdminMenuV2 : HomeMenuV2;
};

const AppLayout = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const navigate = useNavigate();
  const { isDesktop } = useLayoutContext();
  const { businessId } = useParams();
  const AppMenu = getCurrentMenu(businessId, location.pathname);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user]);

  return (
    <BusinessContextProvider>
      <VenueContextProvider>
        <LayoutV2 location={location.pathname}>
          <Box minWidth="1080px">
            <LayoutV2.Title>
              <HStack>
                <AppTitleV2 />
                {isDesktop && (
                  <Box>
                    <CurrentUserV2 />
                  </Box>
                )}
              </HStack>
            </LayoutV2.Title>
          </Box>
          <LayoutV2.Menu>
            {!isDesktop && (
              <HStack w="100%" bg="#fff">
                <Image src={ebetLogo} w="100px" title="Logo" />
                <CurrentUserV2 />
              </HStack>
            )}
            {!!user && (
              <VStack spacing="2" w="100%">
                <AppMenu isDesktop={isDesktop} />
              </VStack>
            )}
          </LayoutV2.Menu>
          <LayoutV2.Content>
            <Box height="auto" overflow="auto">
              <ErrorHandler
                renderErrorState={() => (
                  <ErrorMessage>
                    There was an error loading the page.
                  </ErrorMessage>
                )}
              >
                <ModelContextProvider>
                  <Outlet />
                </ModelContextProvider>
              </ErrorHandler>
            </Box>
          </LayoutV2.Content>
        </LayoutV2>
      </VenueContextProvider>
    </BusinessContextProvider>
  );
};

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { user, isLoading } = useUserContext();

  if (isLoading) {
    return <LoadingIndicator />;
  } else if (!user) {
    return <Login />;
  }

  return children;
};

const LoginRoute = () => {
  const navigate = useNavigate();
  const onLoginSuccess = useCallback(() => {
    navigate("/");
  }, [navigate]);
  return <Login onLoginSuccess={onLoginSuccess} />;
};

export const AppRoutesV2 = () => (
  <Routes>
    <Route
      element={
        <RequireAuth>
          <AppLayout />
        </RequireAuth>
      }
    >
      <Route path="/" element={<Businesses />} />

      <Route path="/settings" element={<Settings />} />
      <Route
        path="/admin/businesses/:businessId/settings"
        element={<Settings />}
      />

      <Route path="/admin/businesses" element={<Businesses />} />
      <Route
        path="/admin/businesses/:businessId"
        element={<BusinessDetailV2 />}
      />

      <Route path="/admin/businesses/:businessId/venues" element={<Venues />} />
      <Route
        path="/admin/businesses/:businessId/venues/:venueId"
        element={<VenueDetail />}
      />
      <Route
        path="/admin/businesses/:businessId/venues/new"
        element={<AddVenue />}
      />

      <Route path="/admin/businesses/:businessId/users" element={<Users />} />
      <Route
        path="/admin/businesses/:businessId/users/:userId"
        element={<UserDetail />}
      />
      <Route
        path="/admin/businesses/:businessId/users/update/:userId"
        element={<UserUpdate />}
      />

      <Route
        path="/admin/businesses/:businessId/members"
        element={<Members />}
      />
      <Route
        path="/admin/businesses/:businessId/members/:userId"
        element={<MemberDetail />}
      />

      <Route
        path="/admin/businesses/:businessId/apikeys"
        element={<Apikeys />}
      />
      <Route
        path="/admin/businesses/:businessId/apikeys/:apikeyId"
        element={<ApikeyDetail />}
      />
      <Route path="/sessions" element={<Sessions />} />
      <Route
        path="/admin/businesses/:businessId/sessions"
        element={<Sessions />}
      />

      <Route path="/reports" element={<Reports />} />
      <Route path="/reports/kyc" element={<KycReport />} />
      <Route path="/reports/member" element={<MemberReport />} />
      <Route path="/reports/otp" element={<OtpReport />} />

      <Route
        path="/admin/businesses/:businessId/reports"
        element={<BusinessReports />}
      />
      <Route
        path="/admin/businesses/:businessId/reports/kyc"
        element={<BusinessKycReport />}
      />
      <Route
        path="/admin/businesses/:businessId/reports/member"
        element={<BusinessMemberReport />}
      />
      <Route
        path="/admin/businesses/:businessId/reports/otp"
        element={<BusinessOtpReport />}
      />

      {/* <Route path="/admin/users" element={<Users />} /> */}
      {/* <Route path="/admin/users/new" element={<UserDetail create={true} />} />
      <Route path="/admin/users/:uid" element={<UserDetail />} /> */}

      {/* <Route path="/admin/members" element={<Members />} />
      <Route path="/admin/members/:memberId" element={<MemberDetail />} /> */}
    </Route>
    <Route
      path="/admin/change-password"
      element={
        <RequireAuth>
          <ChangePassword />
        </RequireAuth>
      }
    />
    <Route path="/login" element={<LoginRoute />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
  </Routes>
);
