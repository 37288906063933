import { Box, HStack, Spacer, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { LoadingIndicator } from "../../components/LoadingIndicator/LoadingIndicator";
import { PageContent } from "../../components/PageContent/PageContent";
import { PageHeading } from "../../components/PageHeading/PageHeading";
import RoundedBackButton from "../../components/ReoundedBackButton/RoundedBackButton";
import { useBusiness } from "../../context/BusinessContext";
import { useProfile } from "../../context/ProfileContext";
import DateUtil from "../../util/DateUtil";
import { Statistic } from "./Section/Statistic";
import { VenueTable } from "./Section/VeneuTable";

export const BusinessDetailV2 = () => {
  const navigate = useNavigate();
  const profile = useProfile();
  const contextBusiness = useBusiness();

  if (!contextBusiness) {
    return (
      <Box>
        <LoadingIndicator color="cherryUi.600" />
      </Box>
    );
  }

  return (
    <>
      <PageHeading customStyle={{ height: "164px" }}>
        <HStack mt={-6}>
          {profile.isWorldAdmin && (
            <>
              <RoundedBackButton onClick={() => navigate("/")} />
              <Spacer w="8px" />
            </>
          )}

          <VStack alignItems="start">
            <Box>
              <PageHeading.Title>
                {contextBusiness
                  ? contextBusiness?.srcBusiness?.name ?? contextBusiness?.title
                  : "New Business"}
              </PageHeading.Title>
            </Box>
            <HStack spacing="10" mt={0}>
              <Text color="white" fontFamily="Poppins" fontSize="13px">
                id: {contextBusiness.srcBusiness?.id ?? "N/A"}
              </Text>
              <Text color="white" fontFamily="Poppins" fontSize="13px">
                ABN: {contextBusiness.abn?.Abn ?? "N/A"}
              </Text>
              <Text color="white" fontFamily="Poppins" fontSize="13px">
                created:{" "}
                {DateUtil.getFormattedDateWithoutTimeZone(
                  contextBusiness.createdAt,
                  "dd/MM/yyyy"
                )}
              </Text>
              <Text color="white" fontFamily="Poppins" fontSize="13px">
                edited:{" "}
                {DateUtil.getFormattedDateWithoutTimeZone(
                  contextBusiness.updatedAt,
                  "dd/MM/yyyy"
                )}
              </Text>
            </HStack>
          </VStack>
        </HStack>
      </PageHeading>
      <PageContent>
        <Statistic />
        <VenueTable />
      </PageContent>
    </>
  );
};
